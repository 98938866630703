import { useLocationMatch } from '@kaliber/routing'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { useClientConfig } from '/machinery/ClientConfig'

/** @type {React.Context<any>} */
export const translationsContext = React.createContext(null)

export function TranslationsProvider({ children, translations: alternatives }) {
  const { route, params } = useLocationMatch()
  const language = useLanguage()
  const { language: { available: availableLanguages } } = useClientConfig()

  const translations = availableLanguages.map(x => {
    const document = alternatives.find(alt => alt.language === x)

    return {
      language: x,
      current: x === language,
      href:
        x === language ? route(params) :
        document ? determineDocumentPathSync({ document, routeMap }) :
        routeMap.app.home({ language })
    }
  })

  return <translationsContext.Provider value={translations} {...{ children }} />
}

export function useTranslations() {
  const translations = React.useContext(translationsContext)
  if (!translations) throw new Error('Please use a `TranslationsProvider` to provide a client config')
  return translations
}

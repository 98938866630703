import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { toPlainText } from '@portabletext/react'
import dayjs from 'dayjs'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const { extract, language, slug } = parameterExtraction

const snippets = createGroqSnippets()
const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',
        subscribeToNewsletter: {
          path: 'newsletter/subscribe',
          data: requestHandlers => requestHandlers.subscribeToMailchimpList
        },
        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home.data,
      },
      contact: {
        path: 'contact',
        data: routeData.app.contact.data,
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      agenda: {
        path: 'agenda',
        data: routeData.app.agenda.data,
        activity: {
          path: ':slug',
          data: routeData.app.activity.data
        },
      },
      static: 'static',
      notFound: '*',
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: [
            groq`*[_type == 'settings' && language == $language] | order(_updatedAt desc) [0] {
              organisatienaam,
              shareImage ${snippets.imageProjection},
              'translations': ${snippets.translations()}
            }`,
            { language }
          ],
          openingHours: groq`*[_type == 'openingHours'] | order(_updatedAt desc) [0]`,
          footer: [
            groq`*[_type == 'footer' && language == $language] | order(_updatedAt desc) [0] {
              contactColumns[] {
                content[] {
                  ...,
                  markDefs[] {
                    ...,
                    ref->
                  }
                }
              },
              newsLetterLink,
              'translations': ${snippets.translations()}
            }`,
            { language }
          ],
          menu: [
            groq`*[_type == 'menu' && language == $language] | order(_updatedAt desc) [0] {
              items[] {
                _key,
                label,
                _type == 'internalLink' => {_type, label, ref->, openInNewTab},
                _type == 'externalLink' => {_type, label, href, openInNewTab},
                _type == 'subMenu' => {
                  ...,
                  items[] {
                    ...,
                    _type == 'internalLink' => {_type, label, ref->, openInNewTab},
                    _type == 'externalLink' => {_type, label, href, openInNewTab},
                  }
                }
              },
              ticketLink,
              'translations': ${snippets.translations()}
            }`,
            { language }
          ],
        }),
        fetch: { languageConfig: requestHandlers => requestHandlers.exampleLanguageConfigFetch }
      },
      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'home' && language == $language] | order(_updatedAt desc) [0] {
                title,
                color,
                seo,
                _id,
                _type,
                _updatedAt,
                _createdAt,
                language,
                header {
                  links,
                  infoMessage,
                  image ${snippets.imageProjection},
                  openingHoursLink {
                    ref->,
                    openInNewTab
                  },
                  links[] ${internalOrExternalLinkProjection()}
                },

                sections[] {
                  _key,
                  _type,
                  color,
                  contentPosition,
                  _type == 'contentAndCarousel' => {
                    carousel,
                    link[] ${internalOrExternalLinkProjection()},
                    content[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'imageAndQuote' => {
                    image ${snippets.imageProjection},
                    quote {
                      author,
                      text
                    }
                  },
                  _type == 'contentAndImage' => {
                    title,
                    image ${snippets.imageProjection},
                    imageShape,
                    link[] ${internalOrExternalLinkProjection()},
                    content[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'contentAndAgenda' => {
                    image ${snippets.imageProjection},
                    link[] ${internalOrExternalLinkProjection()},
                    content[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    },
                  }
                },
                'translations': ${snippets.translations()}
              }`,
              { language }
            ],
            agenda: [
              groq`*[_type == 'activity' && language == $language && count((dates[].activityDate)[@ >= $now]) > 0][0...5] {
                'activity': {
                 'date': (dates[].activityDate)[@ >= $now][0],
                 datetext,
                 _id,
                 _type,
                 title,
                 language,
                 slug
                }
              } | order(activity.date asc)`,
              { language, now: dayjs().utc().startOf('day').format() }
            ]
          }),
          derived: ({ data, derived }) => data.doc && ({
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: data.doc.intro,
              social: {
                shareImage: data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              metadata: {
                content: {
                  title: data.doc.title,
                  id: data.doc._id,
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                },
                template: {
                  type: data.doc._type,
                }
              }
            }
          }),
          extractParams: { home: extract(language) }
        }
      },

      contact: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'contact' && language == $language] | order(_updatedAt desc) [0] {
                title,
                blocks[] {
                  ...,
                  markDefs[] {
                    ...,
                    ref->
                  }
                },
                color,
                contentColor,
                travelOptions,
                seo,
                _id,
                _type,
                _updatedAt,
                _createdAt,
                language,
                latLng {
                  lng,
                  lat,
                  alt
                },
                link[] ${internalOrExternalLinkProjection()},
                'translations': ${snippets.translations()}
              }`,
              { language }
            ],
          }),
          derived: ({ data, derived }) => data.doc && ({
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: toPlainText(data.doc.blocks ?? []),
              social: {
                shareImage: data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              metadata: {
                content: {
                  title: data.doc.title,
                  id: data.doc._id,
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                },
                template: {
                  type: data.doc._type,
                }
              }
            }
          }),
          extractParams: { contact: extract(language) }
        }
      },

      page: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'page' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                title,
                color,
                wrapperColor,
                intro,
                seo,
                _id,
                _type,
                _updatedAt,
                _createdAt,
                language,
                header[0] {
                  _key,
                  _type,
                  _type == 'imageHeader' => {
                    image ${snippets.imageProjection}
                  },
                  _type == 'colorHeader' => {
                    color
                  },
                  _type == 'videoHeader' => {
                    image,
                    vimeo
                  }
                },
                content[] {
                  _key,
                  _type,
                  _type == 'video' => {
                    src
                  },
                  _type == 'imageWithText' => {
                    image ${snippets.imageProjection},
                    position,
                    blocks[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'imageCarousel' => {
                    slides[] {
                      _key,
                      _type,
                      alt,
                      asset,
                    }
                  },
                  _type == 'imageAndQuote' => {
                    ...,
                    image ${snippets.imageProjection},
                  },
                  _type == 'contentCarousel' => {
                    slides[] {
                      _type == 'image' => {
                        _key,
                        _type,
                        alt,
                        asset
                      },
                      _type == 'card' => {
                        _key,
                        _type,
                        title,
                        text,
                        link[] ${internalOrExternalLinkProjection()}
                      }
                    }
                  },
                  _type == 'table' => {
                    title,
                    items[] {
                      _key,
                      _type,
                      label,
                      value
                    },
                    link[] ${internalOrExternalLinkProjection()},
                    content[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'faq' => {
                    title,
                    link[] ${internalOrExternalLinkProjection()},
                    questions[] {
                      _key,
                      question,
                      answer
                    }
                  },
                  _type == 'downloads' => {
                    items[] {
                      _key,
                      _type,
                      title,
                      content[] {
                        ...,
                        markDefs[] {
                          ...,
                          ref->
                        }
                      },
                      image ${snippets.imageProjection},
                      file {
                        _type,
                        asset->
                      }
                    }
                  },
                  _type == 'cta' => {
                    color,
                    title,
                    image ${snippets.imageProjection},
                    links[] ${internalOrExternalLinkProjection()}
                  },
                  _type == 'block' => {
                    ...,
                    markDefs[] {
                      ...,
                      ...${internalOrExternalLinkProjection()}
                    },
                  }
                },
                'translations': ${snippets.translations()}
              }`,
              { language, slug }
            ],
          }),
          extractParams: { page: extract(language, slug) },
          derived: ({ data, derived }) => data.doc && {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: data.doc.intro,
              social: {
                shareImage: data.doc.hero
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              metadata: {
                content: {
                  title: data.doc.title,
                  id: data.doc._id,
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                },
                template: {
                  type: 'content',
                }
              }
            }
          },
        },
      },

      agenda: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'agenda' && language == $language] | order(_updatedAt desc) [0] {
                title,
                color,
                wrapperColor,
                seo,
                _id,
                _type,
                _updatedAt,
                _createdAt,
                language,
                header[0] {
                  _key,
                  _type,
                  _type == 'imageHeader' => {
                    image {
                      _type,
                      alt,
                      asset
                    }
                  },
                  _type == 'colorHeader' => {
                    color
                  },
                  _type == 'videoHeader' => {
                    image,
                    vimeo
                  }
                },
                'translations': ${snippets.translations()}
              }`,
              { language }
            ],
            agenda: [
              groq`*[_type == 'activity' && count((dates[].activityDate)[@ > now()]) > 0] {
                'activity': {
                'date': (dates[].activityDate)[@ > now()][0],
                datetext,
                _id,
                _type,
                title,
                language,
                slug
                }
              } | order(activity.date asc)`,
              { language, now: dayjs().utc().startOf('day').format() }
            ]
          }),
          derived: ({ data, derived }) => data.doc && ({
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: toPlainText(data.doc.blocks ?? []),
              social: {
                shareImage: data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              metadata: {
                content: {
                  title: data.doc.title,
                  id: data.doc._id,
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                },
                template: {
                  type: data.doc._type,
                }
              }
            }
          }),
          extractParams: { agenda: extract(language) }
        }
      },

      activity: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'activity' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                title,
                color,
                wrapperColor,
                seo,
                _id,
                _type,
                _updatedAt,
                _createdAt,
                language,
                header[0] {
                  _key,
                  _type,
                  _type == 'imageHeader' => {
                    image {
                      _type,
                      alt,
                      asset
                    }
                  },
                  _type == 'colorHeader' => {
                    color
                  },
                  _type == 'videoHeader' => {
                    image,
                    vimeo
                  }
                },
                dates[] {
                  _key,
                  activityDate,
                },
                datetext,
                content[] {
                  _key,
                  _type,
                  _type == 'video' => {
                    src
                  },
                  _type == 'imageWithText' => {
                    image ${snippets.imageProjection},
                    position,
                    blocks[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'imageCarousel' => {
                    slides[] {
                      _key,
                      _type,
                      alt,
                      asset,
                    }
                  },
                  _type == 'imageAndQuote' => {
                    ...,
                    image {..., asset->},
                  },
                  _type == 'contentCarousel' => {
                    slides[] {
                      _type == 'image' => {
                        _key,
                        _type,
                        alt,
                        asset
                      },
                      _type == 'card' => {
                        _key,
                        _type,
                        title,
                        text,
                        link[] ${internalOrExternalLinkProjection()}
                      }
                    }
                  },
                  _type == 'table' => {
                    title,
                    items[] {
                      _key,
                      _type,
                      label,
                      value
                    },
                    link[] ${internalOrExternalLinkProjection()},
                    content[] {
                      ...,
                      markDefs[] {
                        ...,
                        ref->
                      }
                    }
                  },
                  _type == 'faq' => {
                    title,
                    link[] ${internalOrExternalLinkProjection()},
                    questions[] {
                      _key,
                      question,
                      answer
                    }
                  },
                  _type == 'downloads' => {
                    items[] {
                      _key,
                      _type,
                      title,
                      content[] {
                        ...,
                        markDefs[] {
                          ...,
                          ref->
                        }
                      },
                      image ${snippets.imageProjection},
                      file {
                        _type,
                        asset->
                      }
                    }
                  },
                  _type == 'cta' => {
                    color,
                    title,
                    image ${snippets.imageProjection},
                    links[] ${internalOrExternalLinkProjection()}
                  },
                  _type == 'block' => { ... }
                },
                'translations': ${snippets.translations()}
              }`,
              { language, slug }
            ],
          }),
          extractParams: { activity: extract(language, slug) },
          derived: ({ data, derived }) => data.doc && {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.header?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              metadata: {
                content: {
                  title: data.doc.title,
                  id: data.doc._id,
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                },
                template: {
                  type: data.doc._type,
                }
              }
            }
          },
        },
      }
    }
  }
}

function internalOrExternalLinkProjection() {
  return groq`{
    _type == 'internalLink' => {_type, label, ref->, openInNewTab},
    _type == 'externalLink' => {_type, label, href, openInNewTab},
  }`
}

function createGroqSnippets() {
  const linkableDocumentFields = groq`_type, slug, language`
  const linkableDocumentObject = groq`{${linkableDocumentFields}}`
  const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields} }`
  const imageProjection = groq`{
    _type,
    alt,
    asset,
    crop,
    hotspot
  }`

  const singleDocumentFilter = groq`
    *[_type == $type && language == $language && slug.current == $slug] |
    order(_updatedAt desc) [0]
  `
  return {
    linkableDocumentFields,
    linkableDocumentObjectWithTitle,
    linkableDocumentObject,
    imageProjection,
    contentObject: groq`{
      ...,
      markDefs[] {
        ...,
        _type == 'internalLink' => { ref->${linkableDocumentObject} }
      }
    }`,
    singletonDocument(type, projection, { language }) {
      return [
        groq`
          *[_type == $type && language == $language] |
          order(_updatedAt desc) [0] ${projection}
        `,
        { type, language }
      ]
    },
    document(type, projection, { language, slug }) {
      return [
        groq`${singleDocumentFilter} ${projection}`,
        { type, language, slug }
      ]
    },
    translations() {
      return groq`
        *[
          _id != ^._id &&
          _type == ^._type &&
          translationId == ^.translationId
        ]
        {
          title,
          _type,
          slug,
          language
        }
      `
    },
  }
}
